import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { logout } from "@mobilemind/common/src/actions/user"
import { getNotifications } from "@mobilemind/common/src/actions/notifications"
import { sendMessage } from "../actions"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"

import { getUserGoals } from "../features/goals/goalsSlice"
import { unmasqueradeUser } from "../store/reducers/session"

import { Dialog, Menu, MenuItem } from "@mui/material"
import { isMobile } from "react-device-detect"
import {
  setSidebar,
  collapseSidebar,
  getNPSSurvey,
  startProductTour,
} from "../actions"
import { changeNavbar } from "../store/reducers/navbar"
import { updateProfile } from "@mobilemind/common/src/actions/user"
import { getLearningPaths } from "@mobilemind/common/src/actions/course"
import theme from "@mobilemind/common/src/theme/theme"

import NPSSurvey from "./NPSSurvey"
import NotificationsMenu from "@mobilemind/common/src/components/NotificationsMenu"
import { switchActiveMembership } from "@mobilemind/common/src/actions/user"
import OrgSwitcher from "@mobilemind/common/src/components/orgSwitcher/OrgSwitcher"

import "../styles/navbar.scss"

import { withRouter } from "react-router-dom"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({
  notifications,
  badges,
  assessments,
  learningPaths,
  courses,
  goals,
  sidebar,
  announcements,
  session,
  extension,
  navbar,
}) => {
  let surveyAnnouncements =
    announcements.data &&
    announcements.data.filter(
      (announcement) =>
        announcement.field_survey && announcement.field_survey.length
    )
  let lastSurveyAnnouncement =
    surveyAnnouncements && surveyAnnouncements.slice(-1)[0]

  let showNewSurvey

  if (session.netPromoterScoreFetched) {
    // If we have a new survey announcement and they either have no NPS record, or it's from before this announcement
    let lastNPSSurvey = session.netPromoterScore
    showNewSurvey =
      lastSurveyAnnouncement &&
      (!lastNPSSurvey ||
        moment(lastNPSSurvey.attributes.created).isBefore(
          lastSurveyAnnouncement.created[0].value
        ))
  }

  return {
    session,
    notifications: [...notifications.data].reverse(),
    goals,
    courses: courses.courseData,
    learningPaths: learningPaths.data,
    assessments,
    badges: badges.data,
    sidebar,
    announcements,
    extension,
    showNewSurvey,
    activeItem: navbar.activeItem,
  }
}

const mapDispatchToProps = {
  changeNavbar,
  switchActiveMembership,
  unmasqueradeUser,
  setSidebar,
  collapseSidebar,
  getNPSSurvey,
  getLearningPaths,
  getUserGoals,
  startProductTour,
  getNotifications,
  updateProfile,
  sendMessage,
  logout,
}

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  startProductTour = () => {
    this.setState({ anchorEl: null })
    this.props.collapseSidebar(true)
    this.props.startProductTour()
  }

  startOnboarding = async () => {
    this.props.updateProfile(
      {
        name:
          this.props.session.user.attributes.field_first_name +
          " " +
          this.props.session.user.attributes.field_last_name,
      },
      this.props.session
    )
  }

  render() {
    const { anchorEl } = this.state
    let isSales = this.props.session.roles.find(
      (role) => role.target_id === "mm_sales"
    )
    let userAttributes = this.props.session.user.attributes
    return (
      <div>
        {this.props.profilePicSrc ? (
          <div
            className="profilePicture"
            style={{ backgroundImage: "url(" + this.props.profilePicSrc + ")" }}
            aria-owns={anchorEl ? "user-menu" : undefined}
            onClick={this.handleClick}
          ></div>
        ) : (
          <div
            className="profilePicture noPic"
            aria-owns={anchorEl ? "user-menu" : undefined}
            onClick={this.handleClick}
          >
            <span
              style={{
                backgroundColor: theme.palette.secondary.main,
              }}
              className="userInitial"
            >
              <span style={{ color: "black" }}>
                {userAttributes.field_first_name &&
                  userAttributes.field_first_name.charAt(0).toUpperCase()}
                {userAttributes.field_last_name &&
                  userAttributes.field_last_name.charAt(0).toUpperCase()}
              </span>
            </span>
          </div>
        )}
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem>
            <Link onClick={this.handleClose} to="/profile">
              Your Profile
            </Link>
          </MenuItem>
          {!isMobile && (
            <MenuItem>
              <Link onClick={this.startProductTour} to="/">
                Start Product Tour
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <Link onClick={this.handleClose} to="/submit-ticket">
              Submit a Ticket
            </Link>
          </MenuItem>

          {isSales && (
            <MenuItem>
              <Link onClick={this.startOnboarding} to="/">
                Start Onboarding
              </Link>
            </MenuItem>
          )}

          <MenuItem onClick={this.handleClose}>
            <Link
              onClick={() => {
                this.props.sendMessage({ type: "logout" })
                this.props.logout()
              }}
              to="/"
            >
              Logout
            </Link>
          </MenuItem>
          {this.props.extension.version ? (
            <footer>Extension v{this.props.extension.version}</footer>
          ) : (
            <footer>No extension detected</footer>
          )}
        </Menu>
      </div>
    )
  }
}

class Navbar extends Component {
  state = {
    isMobileMenuOpen: false,
  }

  componentDidMount() {
    this.props.getNPSSurvey(this.props.session.user)
    this.props.getNotifications()
  }

  onNotificationClick = (notification) => {
    let type = notification.type,
      path

    if (type === "assessment_feedback") {
      path = "/course/" + notification.course_id
    }
    if (
      type === "ulp_complete" ||
      type === "assessment_complete" ||
      type === "user_badge_created"
    ) {
      path = "/achievements"
    }

    this.props.history.push(path)
  }

  toggleMobileMenu = (value) => {
    this.setState({ isMobileMenuOpen: value })
  }

  navigate = (route) => {
    this.setState({ isMobileMenuOpen: false })
    this.props.changeNavbar(route)
  }

  render() {
    const { session, showNewSurvey, activeItem } = this.props

    let profilePicSrc, showAdminLink

    let isHQ = !session.group || session.group?.field_hq[0]?.value
    let HQLabel = isHQ ? "HQ" : "Admin"

    if (session.orgRoles) {
      if (
        session.orgRoles.includes("organization-admin") ||
        session.orgRoles.includes("partner-admin") ||
        session.orgRoles.includes("organization-creator") ||
        session.orgRoles.includes("organization-drafter") ||
        session.orgRoles.includes("organization-scheduler") ||
        session.orgRoles.includes("organization-reporter") ||
        session.orgRoles.includes("organization-reviewer") ||
        session.orgRoles.includes("organization-event_personnel") ||
        session.orgRoles.includes("organization-observation_admin") ||
        session.orgRoles.includes("organization-observer") ||
        session.orgRoles.includes("organization-group_manager") ||
        !session.group
      ) {
        showAdminLink = true
      }
    }
    if (session.groupRoles) {
      if (
        session.groupRoles.includes("group-group_reviewer") ||
        session.groupRoles.includes("group-admin") ||
        session.groupRoles.includes("group-creator") ||
        session.groupRoles.includes("group-drafter") ||
        session.groupRoles.includes("group-reporter") ||
        session.groupRoles.includes("group-scheduler") ||
        session.groupRoles.includes("group-reviewer") ||
        session.groupRoles.includes("group-group_reporter") ||
        session.groupRoles.includes("group-observation_admin") ||
        session.groupRoles.includes("group-observer") ||
        session.groupRoles.includes("group-user_manager")
      ) {
        showAdminLink = true
      }
    }

    if (session.userPicture) {
      profilePicSrc =
        process.env.REACT_APP_API_URL + session.userPicture.attributes.uri.url
    }

    const leaderboardsVisibility =
      session.group &&
      session.group.field_teacher_leaderboard_visibi &&
      session.group.field_teacher_leaderboard_visibi[0] &&
      session.group.field_teacher_leaderboard_visibi[0].value

    const mainMenu = (
      <>
        <ul className="menu">
          <li
            style={{ animationDelay: ".05s" }}
            className={classNames({ active: activeItem === "learn" })}
          >
            <Link onClick={() => this.navigate("learn")} to="/">
              Learn
            </Link>
          </li>
          <li
            id="tour-navbar-in-progress"
            style={{ animationDelay: ".1s" }}
            className={classNames({ active: activeItem === "in-progress" })}
          >
            <Link
              onClick={() => this.navigate("in-progress")}
              to="/in-progress"
            >
              In Progress
            </Link>
          </li>
          <li
            id="tour-navbar-events"
            style={{ animationDelay: ".25s" }}
            className={classNames({ active: activeItem === "events" })}
          >
            <Link onClick={() => this.navigate("events")} to="/events">
              Events
            </Link>
          </li>
          <li
            id="tour-navbar-backpack"
            style={{ animationDelay: ".15s" }}
            className={classNames({ active: activeItem === "backpack" })}
          >
            <Link onClick={() => this.navigate("backpack")} to="/backpack">
              Backpack
            </Link>
          </li>
          {leaderboardsVisibility && leaderboardsVisibility !== "none" && (
            <li
              id="tour-navbar-leaderboards"
              style={{ animationDelay: ".2" }}
              className={classNames({ active: activeItem === "leaderboards" })}
            >
              <Link
                onClick={() => this.navigate("leaderboards")}
                to="/leaderboards"
              >
                Leaderboards
              </Link>
            </li>
          )}
          {session.isMasquerading && (
            <li>
              <Link
                onClick={() => {
                  this.props.unmasqueradeUser()
                  this.setState({ isUnmasquerading: true })
                }}
              >
                Unmasquerade
              </Link>
            </li>
          )}
        </ul>
      </>
    )

    return (
      <div className="navbar">
        {this.state.isUnmasquerading && (
          <Loading message="Just a moment..." fullPage={true} />
        )}
        <nav style={{ backgroundColor: theme.palette.primary.main }}>
          {isMobile ? (
            <>
              <span
                onClick={() => {
                  this.toggleMobileMenu(true)
                }}
                className="icon menu"
              />
            </>
          ) : (
            <h1 id="tour-mm-logo">
              <Link
                onClick={() => this.props.setSidebar("learn", "dashboard")}
                to="/"
              >
                <img
                  className="logo"
                  src={theme.mixins.images.logo}
                  alt="MobileMind"
                />
              </Link>
            </h1>
          )}

          <Dialog
            id="mobile-menu"
            onClose={() => this.setState({ isMobileMenuOpen: false })}
            open={this.state.isMobileMenuOpen}
          >
            <h3>{mainMenu}</h3>
          </Dialog>

          {mainMenu}

          {showAdminLink && !isMobile && (
            <div className="hqLink">
              <a href={process.env.REACT_APP_HQ_URL}>
                <span className="icon swap" />
                {HQLabel}
              </a>
            </div>
          )}

          <div className="userInfo">
            <strong
              style={{
                top: session.memberships.length > 0 ? 5 : 0,
                right: session.memberships.length > 0 ? 6 : 0,
              }}
            >
              {session.user.attributes.field_first_name &&
                session.user.attributes.field_first_name.toLowerCase()}{" "}
              {session.user.attributes.field_last_name &&
                session.user.attributes.field_last_name.toLowerCase()}
            </strong>
            <span>
              {session.memberships.length > 0 ? (
                <OrgSwitcher
                  activeOrgId={
                    session.user.relationships.field_active_membership.data
                      ? session.user.relationships.field_active_membership.data
                          .id
                      : session.group.uuid[0].value
                  }
                  isHQ={false}
                  memberships={session.memberships}
                  onChange={(selectedGroupId) => {
                    this.props.switchActiveMembership(
                      selectedGroupId,
                      process.env.REACT_APP_TEACHER_URL
                    )
                  }}
                />
              ) : (
                <span>{session.group && session.group.label[0].value}</span>
              )}
            </span>
          </div>

          <UserMenu
            theme={theme}
            session={this.props.session}
            collapseSidebar={() => {
              this.props.collapseSidebar(true)
            }}
            sendMessage={this.props.sendMessage}
            extension={this.props.extension}
            startProductTour={() => {
              this.props.startProductTour("trigger")
            }}
            updateProfile={(profile, session) => {
              this.props.updateProfile(profile, session)
            }}
            profilePicSrc={profilePicSrc}
            logout={() => {
              this.props.logout()
            }}
          />

          <NotificationsMenu
            onClick={(notification) => this.onNotificationClick(notification)}
            {...this.props}
          />
        </nav>
        {showNewSurvey && <NPSSurvey />}
      </div>
    )
  }
}

UserMenu.propTypes = {
  session: PropTypes.object,
  extension: PropTypes.object,
  profilePicSrc: PropTypes.string,
}

Navbar.propTypes = {
  activeItem: PropTypes.string,
  announcements: PropTypes.object,
  extension: PropTypes.object,
  notifications: PropTypes.array,
  session: PropTypes.object,
  sidebar: PropTypes.object,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
