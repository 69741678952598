import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import { generateTimestamp } from "@mobilemind/common/src/functions"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import RatingStar from "@mobilemind/common/src/icons/RatingStar"
import classNames from "classnames"
import ReviewerQuote from "@mobilemind/common/src/components/ReviewerQuote"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import { format, fromUnixTime } from "date-fns"
import he from "he"

interface CapsuleProps {
  animationDelay?: number
  event?: any
  activeItem?: string
  onClick?: (event: any) => void
  preview?: boolean
}

const UnapprovedCard = ({
  animationDelay,
  event,
  activeItem,
  onClick,
  preview,
}: CapsuleProps) => {
  const totalMinutes = Number(event.field_total_time_value)
  const hours = convertMinutesToHoursMinutes(totalMinutes).hours
  const minutes = convertMinutesToHoursMinutes(totalMinutes).minutes

  const isDeclined = event.field_status_value === "declined"
  const isPending = event.field_status_value === "pending"

  const { categories, session } = useSelector((state: RootState) => state)

  return (
    <li
      className="request-list-item"
      style={{ animationDelay: animationDelay + "s" }}
    >
      <Accordion expanded={activeItem === event.id || preview}>
        <AccordionSummary onClick={onClick}>
          <div className="flexRow">
            <span className="icon external-pd color" />

            <div style={{ flex: 1, marginLeft: 10 }}>
              <h3>{event.title}</h3>
              <span className="timestamp">
                {format(
                  new Date(event.field_start_date_value),
                  "MMMM do, yyyy"
                )}
              </span>
            </div>

            <TimeCapsule hours={hours} minutes={minutes} />

            {isPending && <span className="icon raised-hand" />}
            {isDeclined && <span className="icon declined" />}
            <strong className="requested-time">
              Approved {generateTimestamp(fromUnixTime(event.changed))}
            </strong>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <div className="request-content">
            <div className="column">
              {event.description__value && (
                <section>
                  <header>Description</header>
                  <SanitizedHTML html={event.description__value} />
                </section>
              )}

              {event.field_learner_comment_value && (
                <section>
                  <header>Comments</header>
                  <div className="flexRow">
                    <SanitizedHTML html={event.field_learner_comment_value} />
                  </div>
                </section>
              )}

              {event.reviewer &&
                event.field_rev_comment_value &&
                event.field_rev_comment_value.length > 0 && (
                  <div
                    className="reviewer-comments"
                    style={{ display: "inline-block" }}
                  >
                    <ReviewerQuote
                      groupLabel={session.group.label[0].value}
                      imageSrc={event.reviewer.reviewer_pic}
                      firstName={event.reviewer.reviewer_first_name}
                      lastName={event.reviewer.reviewer_last_name}
                      text={event.field_rev_comment_value}
                    />
                  </div>
                )}
            </div>

            <div className="column">
              <section>
                <header>PD Categories</header>
                <ul>
                  {event.timeBlocks &&
                    event.timeBlocks.map((block: any) => {
                      const category = categories.data.find(
                        (cat: any) => cat.id === block.categoryId
                      )
                      if (category) {
                        return (
                          <li
                            style={{
                              padding: "5px 0",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="category-icon"
                              style={{
                                width: 30,
                                height: 30,
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_API_URL +
                                  category.image.attributes.uri.url +
                                  ")",
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              {category.attributes.name}
                            </div>
                            <TimeCapsule
                              hours={block.hours}
                              minutes={block.minutes}
                            />
                          </li>
                        )
                      }
                      return null
                    })}
                </ul>
              </section>

              <section className="ratingContainer">
                <header>Your Rating</header>
                <div className="flexRow">
                  {[1, 2, 3, 4, 5].map((value) => {
                    return (
                      <RatingStar
                        key={value}
                        selected={event.field_rating_value >= value}
                      />
                    )
                  })}
                </div>
              </section>

              {event.attachments.length > 0 && (
                <section>
                  <header>Attachments</header>
                  <ul className="pd-attachment-list">
                    {event.attachments.map((file: any) => {
                      return (
                        <li key={file.filename}>
                          {file.extension !== "png" &&
                          file.extension !== "jpg" &&
                          file.extension !== "jpeg" ? (
                            <span
                              className={classNames("icon", file.extension)}
                            />
                          ) : (
                            <span
                              className={classNames("icon image")}
                              style={{
                                backgroundImage: "url(" + file.file + ")",
                              }}
                            />
                          )}
                          {decodeURIComponent(
                            he.decode(file.filename)
                          ).replaceAll("&#x202F;", " ")}
                        </li>
                      )
                    })}
                  </ul>
                </section>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </li>
  )
}

export default UnapprovedCard
