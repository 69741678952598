import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import _ from "lodash"
import moment from "moment"

export const fetchUpcomingEvents = createAsyncThunk(
  "dashboardSlice/fetchUpcomingEvents",
  async (args, thunkAPI) => {
    let query = {
      start: moment().startOf("day").format("YYYY-MM-DD"),
      end: moment().add(3, "months").endOf("month").format("YYYY-MM-DD"),
    }

    let response = await fetchWrapper.get(
      "/api/event_explore?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      const orderedData = _.orderBy(data.data, [
        (event) => moment(event.event_start).format(),
      ])

      return {
        RSVPd: orderedData
          .filter((event) => event.rsvp_value === "accept")
          .slice(0, 3),
        upcoming: orderedData.slice(0, 3),
      }
    }
  }
)

export const getLPData = createAsyncThunk(
  "dashboardSlice/getLPData",
  async (args, thunkAPI) => {
    const { isPartner } = thunkAPI.getState().session

    const url = isPartner
      ? "/api/mm_partner_portal/lp_explore?"
      : "/api/learning_path/explore?"

    let inProgress = await fetchWrapper.get(
      url +
        qs.stringify({
          status: "in_progress",
          sort_by: "updated",
          sort_order: "DESC",
        })
    )

    let unstarted = await fetchWrapper.get(
      url +
        qs.stringify({
          status: "not_started",
        })
    )

    // Find a LP with a goal
    let LPsWithGoal = []
    let inProgressLPs
    if (inProgress.ok) {
      let inProgressData = await inProgress.json()
      inProgressLPs = inProgressData.lp_data

      inProgressData.lp_data.forEach((path) => {
        if (
          path.lp_goal_info &&
          path.lp_goal_info[0] &&
          path.lp_goal_info[0].user_lp_goal_status
        ) {
          LPsWithGoal.push(path)
        }
      })
    }

    if (unstarted.ok) {
      let unstartedData = await unstarted.json()
      unstartedData.lp_data.forEach((path) => {
        if (
          path.lp_goal_info &&
          path.lp_goal_info[0] &&
          path.lp_goal_info[0].user_lp_goal_status
        ) {
          LPsWithGoal.push(path)
        }
      })
    }

    if (LPsWithGoal.length) {
      return LPsWithGoal
    } else {
      return {
        noGoals: true,
        path: inProgressLPs[inProgressLPs.length - 1],
      }
    }
  }
)

export const getInProgressCourses = createAsyncThunk(
  "dashboardSlice/getInProgressCourses",
  async (args, thunkAPI) => {
    const { isPartner } = thunkAPI.getState().session

    const url = isPartner
      ? "/api/mm_partner_portal/course_explore?"
      : "/api/course_entity/explore?"

    let inProgress = await fetchWrapper.get(
      url +
        qs.stringify({
          status: "in_progress",
        })
    )

    if (inProgress.ok) {
      let data = await inProgress.json()

      let sorted = _.orderBy(data.data, [
        (course) => course.field_user_assessment_last_updated,
      ])

      let firstWithComment = sorted.find(
        (course) => course.field_user_assessment_comment.length
      )
      let noComments = sorted.filter(
        (course) => !course.field_user_assessment_comment
      )

      return {
        feedback: firstWithComment,
        inProgress: noComments.slice(0, 3),
      }
    }
  }
)

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: {
    quickLinks: {},
    data: [],
  },
  reducers: {
    getQuickLinks: (state, action) => {
      state.quickLinks = action.payload
    },
    getAssessments: (state, action) => {
      let status = action.meta.status
      let assessments = action.payload.data.slice(0, 3)

      // if (status === "in_progress") {
      //   state.inProgress = {
      //     label: "Jump Back In",
      //     type: "course-list",
      //     targetRoute: "in-progress",
      //     targetPage: "inProgress",
      //     targetTab: "in_progress",
      //     assessments,
      //   }
      // }
      if (status === "saved_for_later") {
        state.savedForLater = {
          label: "Ready for Launch",
          type: "course-list",
          targetRoute: "in-progress",
          targetPage: "inProgress",
          targetTab: "saved_for_later",
          assessments,
        }
      }
    },
    getRecentUserBadge: (state, action) => {
      if (action.payload) {
        // state.recentBadge = {
        //   label: "Keep at it",
        //   badge: action.payload,
        // }
      }
    },
  },
  extraReducers: {
    [getInProgressCourses.fulfilled]: (state, action) => {
      state.feedback = action.payload ? action.payload.feedback : []
    },
    [fetchUpcomingEvents.fulfilled]: (state, action) => {
      state.sync = action.payload
    },
    [getLPData.fulfilled]: (state, action) => {
      state.learningPathsData = action.payload

      if (action.payload.noGoals) {
        state.learningPathInProgress = action.payload.path
      } else {
        state.goalsData = action.payload
      }
    },
  },
})

export default dashboardSlice.reducer
