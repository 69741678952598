import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import moment from "moment"
import he from "he"
import qs from "qs"
import { RootState } from "../store"
import _ from "lodash"
import {
  saveRequestAttachments,
  saveExternalTimeBlocks,
} from "@mobilemind/common/src/actions/externalPD"
import { UTCToLocalTime } from "@mobilemind/common/src/functions"
import { PreApprovalFormEntity } from "../../features/events/types"

export const fetchExternalPDRequests = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/fetchExternalPDRequests", async (args, thunkAPI) => {
  let data: any = undefined
  const { externalPD } = thunkAPI.getState()
  /**
   * creates an endpoint for viewing user External Events.
   * * /api/mm_ext_usr_event/learn/
   *
   * also supports filters for user name search, category, org,
   * subgroup.
   * * /api/mm_ext_usr_event/learn/?
   * * * status= [str] (pending, accepted, declined, show_accepted)
   * * * &start_date= [str]
   * * * &title= [str]
   * * * &category= [str]
   * * * &page[offset]= [int]
   * * * &sort_by= [str (start_date, title, total_time, category)]
   * * * &sort_order= [str (ASC, DESC)]
   */
  let query = {
    status: "",
    page: {
      offset: 0,
    },
  }

  if (args.status) {
    query.status = args.status
    if (args.status === "accepted") {
      query.page.offset = 25 * externalPD.approved.currentPage
    }
  }

  let response = await fetchWrapper.get(
    "/api/mm_ext_usr_event/learn?" + qs.stringify(query)
  )

  if (response.ok) {
    data = await response.json()

    data.external_user_event_data.forEach((event: any) => {
      event.drupal_internal__id = event.id
      event.id = event.uuid
      event.field_rating_value = Number(event.field_rating_value)
      event.field_total_time_value = Number(event.field_total_time_value)

      type CategoryData = {
        uuid: string
        category_name: string
        category_uuid: string
        category_time_spent: string
      }

      // Handle category "Time Blocks," which is our Paragraph with category and time info
      if (event.category_data) {
        event.timeBlocks = event.category_data.map((block: CategoryData) => {
          let totalMinutes = Number(block.category_time_spent)
          return {
            id: block.uuid,
            hours: totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0,
            minutes: totalMinutes % 60,
            categoryId: block.category_uuid,
          }
        })
      }

      // Handle attachments
      if (event.attachments_url) {
        event.attachments = event.attachments_url.map((url: string) => {
          return {
            published: true,
            extension: url.split(".")[url.split(".").length - 1],
            filename: decodeURI(url.split("/")[url.split("/").length - 1]),
            file: url,
          }
        })
      }

      // Handle reviewer information
      if (event.reviewer_data && event.reviewer_data[0]) {
        event.reviewer = event.reviewer_data[0]
      }
    })
    return data
  }
})

export const fetchRecommendedEvents = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/fetchRecommendedEvents", async (args, thunkAPI) => {
  const { recommended } = thunkAPI.getState().externalPD
  const { session } = thunkAPI.getState()

  const query = {
    new: recommended.filters.onlyNew,
    page: {
      offset: recommended.currentPage * 50,
    },
  }

  let response = await fetchWrapper.get(
    "/api/mm_rec_ext_event/learn?" + qs.stringify(query)
  )
  let data: any

  if (response.ok) {
    data = await response.json()
    const totalPages = Math.ceil(data.total_records / 50)
    const keys = Object.keys(data.rec_external_event_data)
    data = keys.map((key) => data.rec_external_event_data[key])

    data.forEach((event: any) => {
      if (event.field_start_date_value && event.field_end_date_value) {
        event.field_start_date_value = UTCToLocalTime(
          event.field_start_date_value + "-04:00",
          session.user.attributes.timezone,
          "yyyy-MM-dd'T'HH:mm:ss"
        )
        event.field_end_date_value = UTCToLocalTime(
          event.field_end_date_value + "-04:00",
          session.user.attributes.timezone,
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      }
    })

    return { data, totalPages }
  }
})

export const increaseRecommendedPage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/increaseRecommendedPage", async (args, thunkAPI) => {
  thunkAPI.dispatch(fetchRecommendedEvents(args))
  return args
})

export const increaseAcceptedPage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/increaseAcceptedPage", async (args, thunkAPI) => {
  thunkAPI.dispatch(fetchExternalPDRequests(args))
  return args
})

export const getSingleExternalRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/getSingleExternalRequest", async (args, thunkAPI) => {
  const { event_id } = args

  let response = await fetchWrapper.get("/api/single_ext_usr_event/" + event_id)

  if (response.ok) {
    const data = await response.json()
    return data.data
  }
})

export const saveExternalRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/saveExternalRequest", async (args, thunkAPI) => {
  let data: any = undefined
  const { session } = thunkAPI.getState()
  const { currentRequest, preApprovalNeeded } = args

  let totalMinutes: number = 0
  currentRequest.timeBlocks.forEach((block: any) => {
    totalMinutes += block.minutes
    totalMinutes += block.hours * 60
  })

  let body: any = {
    data: {
      type: "mm_ext_usr_event--mm_ext_usr_event",
      attributes: {
        title: currentRequest.title,
        status: true,
        description: {
          value: currentRequest.description,
        },
        field_learner_comment: currentRequest.comments,
        field_start_date: moment(currentRequest.startDate).format(),
        field_end_date: moment(currentRequest.endDate).format(),
        field_status: preApprovalNeeded ? "awaiting_pre_appr" : "pending",
        field_total_time: totalMinutes,
      },
      relationships: {
        field_user: {
          data: {
            type: "user--user",
            id: session.user.id,
          },
        },
      },
    },
  }

  if (Number(currentRequest.rating)) {
    body.data.attributes.field_rating = Number(currentRequest.rating)
  }

  if (currentRequest.recommendedId) {
    body.data.relationships.field_rec_ext_event = {
      data: {
        type: "mm_rec_ext_event--recommended_external_event",
        id: currentRequest.recommendedId,
      },
    }
  }

  let response
  if (currentRequest.id) {
    body.data.id = currentRequest.id
    response = await fetchWrapper.patch(
      "/api/mm_ext_usr_event/mm_ext_usr_event/" + currentRequest.id,
      session.token,
      JSON.stringify(body)
    )
  } else {
    response = await fetchWrapper.post(
      "/api/mm_ext_usr_event/mm_ext_usr_event",
      session.token,
      JSON.stringify(body)
    )
  }

  if (response.ok) {
    data = await response.json()

    await thunkAPI.dispatch(saveRequestAttachments({ requestId: data.data.id }))
    await thunkAPI.dispatch(saveExternalTimeBlocks({ event: data.data }))
    thunkAPI.dispatch(fetchExternalPDRequests({}))
  }

  return data.data
})

export const saveUserPreApprovalForm = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/saveUserPreApprovalForm", async (args, thunkAPI) => {
  let data: any = undefined
  const { session, externalPD } = thunkAPI.getState()
  const { currentRequest, responsesToSubmit, externalUserEvent } = args

  let response
  let body: any = {
    data: {
      type: "mm_user_form--user_pre_appr",
      attributes: {
        label: currentRequest.title + " Pre-Approval",
      },
      relationships: {
        field_user: {
          data: {
            type: "user--user",
            id: session.user.id,
          },
        },
        field_ext_usr_event: {
          data: {
            type: "mm_ext_usr_event--mm_ext_usr_event",
            id: externalUserEvent.id,
          },
        },
        field_form: {
          data: {
            type: "mm_form--pre_approval",
            id:
              currentRequest.preApprovalFormId ??
              externalPD.preApprovalForms.data[0].id,
          },
        },
      },
    },
  }

  response = await fetchWrapper.post(
    "/api/mm_user_form/user_pre_appr",
    session.token,
    JSON.stringify(body)
  )

  data = await response.json()

  const field_responses = []

  let i = 0
  while (i < responsesToSubmit.length) {
    const questionType = responsesToSubmit[i].question.type
    const questionId =
      responsesToSubmit[i].question.attributes.drupal_internal__id

    const questionBody: any = {
      data: {
        type: "paragraph--form_responses",
        attributes: {
          parent_id: data.data.attributes.drupal_internal__id,
          field_question_name:
            responsesToSubmit[i].question.attributes.field_question_name,
          parent_type: "mm_user_form",
          parent_field_name: "field_responses",
          field_date_answer: null,
          field_fc_answers: null,
          field_open_ended: null,
          field_paragraph_id: questionId,
        },
      },
    }

    // Only submit and post if there's an actual response
    if (responsesToSubmit[i].response) {
      if (questionType === "paragraph--open_ended_questions") {
        questionBody.data.attributes.field_open_ended =
          responsesToSubmit[i].response
      }
      if (questionType === "paragraph--date_question") {
        questionBody.data.attributes.field_date_answer = moment(
          responsesToSubmit[i].response
        ).format()
      }

      if (questionType === "paragraph--fixed_choice_questions") {
        if (Array.isArray(responsesToSubmit[i].response)) {
          questionBody.data.attributes.field_fc_answers =
            responsesToSubmit[i].response.join(",")
        } else {
          questionBody.data.attributes.field_fc_answers =
            responsesToSubmit[i].response
        }
      }

      let questionResponse = await fetchWrapper.post(
        "/api/paragraph/form_responses",
        session.token,
        JSON.stringify(questionBody)
      )

      const questionResponseData = await questionResponse.json()

      field_responses.push({
        type: "paragraph--form_responses",
        id: questionResponseData.data.id,
        meta: {
          target_revision_id:
            questionResponseData.data.attributes.drupal_internal__revision_id,
          drupal_internal__target_id:
            questionResponseData.data.attributes.drupal_internal__id,
        },
      })
    }
    i++
  }

  // Now we've gotta stick the responses on the form
  let formUpdate: any = {
    data: {
      id: data.data.id,
      type: "mm_user_form--user_pre_appr",

      relationships: {
        field_responses: {
          data: field_responses,
        },
      },
    },
  }

  let formUpdateResponse = await fetchWrapper.patch(
    "/api/mm_user_form/user_pre_appr/" + data.data.id,
    session.token,
    JSON.stringify(formUpdate)
  )

  if (formUpdateResponse.ok) {
    data = await formUpdateResponse.json()
    return data.data
  }
})

export const deleteExternalRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/deleteExternalRequest", async (args, thunkAPI) => {
  await fetchWrapper.remove(
    "/api/mm_ext_usr_event/mm_ext_usr_event/" + args.id,
    thunkAPI.getState().session.token
  )
  return args
})

export const getPreApprovalForms = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/getPreApprovalForms", async (args, thunkAPI) => {
  const query = {
    filter: {
      "field_organization.id": thunkAPI.getState().session.group.uuid[0].value,
    },
    include: "field_questions,field_approval_steps",
  }

  const response = await fetchWrapper.get(
    "/api/mm_form/pre_approval?" + qs.stringify(query)
  )
  if (response.ok) {
    const data = await response.json()

    data.data.forEach((form: PreApprovalFormEntity) => {
      form.questions = data.included?.filter((included: any) => {
        return form.relationships?.field_questions?.data.find(
          (question: any) => question.id === included.id
        )
      })

      const approvalStepsForForm = data.included?.filter((included: any) => {
        return form.relationships?.field_approval_steps?.data.find(
          (step: any) => step.id === included.id
        )
      })

      let turnaroundTime = 0

      approvalStepsForForm?.forEach((step: any) => {
        turnaroundTime += step.attributes.field_turn_time
      })

      form.turnaroundTime = turnaroundTime
    })

    return _.orderBy(data.data, "attributes.label", "asc")
  }
  return []
})

type ExternalRequest = {
  id: string
  title: string
  description__value: string
  field_start_date_value: string
  field_end_date_value: string
  field_total_time_value: number
  field_rating_value: number
  field_status_value: string
  field_reviewed_date_value: string | null
  field_rev_comment_value: string | null
  field_learner_comment_value: string | null
  field_rec_ext_event_target_id: string | null
  attachments: {
    published: boolean
    extension: string
    filename: string
    file: string
  }[]
  timeBlocks: {
    id: string
    hours: number
    minutes: number
    categoryId: string
  }[]
  reviewer: {
    reviewer_first_name: string
    reviewer_last_name: string
    reviewer_pic: string
  }
}

type InitialState = {
  unapproved: {
    fetched: boolean
    data: ExternalRequest[]
    total_records: number
  }
  approved: {
    data: ExternalRequest[]
    fetched: boolean
    total_records: number
    totalPages: number
    currentPage: number
    isFetchingMore: boolean
  }
  declined: {
    data: ExternalRequest[]
    fetched: boolean
    total_records: number
  }
  recommended: {
    data: any[]
    fetched: boolean
    currentPage: number
    totalPages: number
    isFetchingMore: boolean
    filters: {
      onlyNew: boolean
    }
  }
  preApprovalForms: {
    data: PreApprovalFormEntity[]
    fetched: boolean
  }
  currentRequest: {
    id: string | null
    recommendedId: string | null
    isOpen: boolean
    title: string
    description: string
    startDate: string
    startDateTime: string
    endDate: string
    endDateTime: string
    comments: string
    rating: number
    isSaving: boolean
    totalAvailableTime: {
      hours: number
      minutes: number
    }
    timeBlocks: {
      id: string | null
      hours: number
      minutes: number
      categoryId: number | null
    }[]
    attachments: any[]
    preApprovalNeeded: boolean
    preApprovalFormId: any
  }
}

const initialState: InitialState = {
  unapproved: {
    data: [],
    fetched: false,
    total_records: 0,
  },
  approved: {
    data: [],
    fetched: false,
    total_records: 0,
    totalPages: 1,
    currentPage: 0,
    isFetchingMore: false,
  },
  declined: {
    data: [],
    fetched: false,
    total_records: 0,
  },
  recommended: {
    data: [],
    fetched: false,
    currentPage: 0,
    totalPages: 1,
    isFetchingMore: false,
    filters: {
      onlyNew: true,
    },
  },
  preApprovalForms: {
    data: [],
    fetched: false,
  },
  currentRequest: {
    id: null,
    recommendedId: null,
    isOpen: false,
    title: "",
    description: "",
    startDate: moment().format(),
    startDateTime: moment().format(),
    endDate: moment().add(1, "hours").format(),
    endDateTime: moment().add(1, "hours").format(),
    comments: "",
    rating: 0,
    isSaving: false,
    totalAvailableTime: {
      hours: 1,
      minutes: 0,
    },
    timeBlocks: [
      {
        id: null,
        hours: 0,
        minutes: 0,
        categoryId: null,
      },
    ],
    attachments: [],
    preApprovalNeeded: false,
    preApprovalFormId: null,
  },
}

export const externalPDSlice = createSlice({
  name: "externalPD",
  initialState,
  reducers: {
    editRequest: (state, action) => {
      const event = action.payload
      const { attachments, timeBlocks, field_total_time_value } = event
      let totalMinutes = field_total_time_value

      // If we're editing (continuing) a pre-approved request,
      // we need to calculate the total available time here
      if (!field_total_time_value) {
        totalMinutes = moment(event.field_end_date_value).diff(
          moment(event.field_start_date_value),
          "minutes"
        )
      }

      //@todo - fix this when loading an event to be edited
      // @ts-ignore
      state.currentRequest = {
        id: event.id,
        isOpen: true,
        recommendedId: null,
        title: event.title,
        description: he.decode(
          event.description__value.replaceAll("<[^>]*>", "")
        ),
        startDate: moment(event.field_start_date_value).format(),
        startDateTime: moment(event.field_start_date_value).format(),
        endDate: moment(event.field_end_date_value).format(),
        endDateTime: moment(event.field_end_date_value).format(),
        comments: event.field_learner_comment_value,
        rating: event.field_rating_value,
        isSaving: false,
        totalAvailableTime: {
          hours: totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0,
          minutes: totalMinutes % 60,
        },
        timeBlocks,
        attachments,
      }
    },
    startNewRequest: (state) => {
      state.currentRequest = initialState.currentRequest
    },
    setPreApprovalNeeded: (state, action) => {
      state.currentRequest.preApprovalNeeded = action.payload
    },
    selectPreApprovalForm: (state, action) => {
      state.currentRequest.preApprovalFormId = action.payload
    },
    claimRecommended: (state, action) => {
      const event = action.payload
      const timeBlocks = event.category_data
      const totalMinutes = Number(event.field_total_time_value)

      state.currentRequest.isOpen = true
      state.currentRequest.recommendedId = event.uuid
      state.currentRequest.title = event.title

      // Clear out any attachments, ratings, comments
      state.currentRequest.comments = ""
      state.currentRequest.rating = 0
      state.currentRequest.attachments = []

      state.currentRequest.timeBlocks = timeBlocks.map((block: any) => {
        let totalMinutes = Number(block.category_time_spent)
        return {
          id: block.uuid,
          hours: totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0,
          minutes: totalMinutes % 60,
          categoryId: block.category_uuid,
        }
      })

      state.currentRequest.totalAvailableTime.hours =
        totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0
      state.currentRequest.totalAvailableTime.minutes = totalMinutes % 60
    },
    toggleRequestModal: (state, action) => {
      state.currentRequest.isOpen = action.payload
    },
    updateTimeBlock: (state, action) => {
      const { index, value } = action.payload
      state.currentRequest.timeBlocks[index] = value
    },
    addTimeBlock: (state) => {
      state.currentRequest.timeBlocks.push({
        id: null,
        hours: 0,
        minutes: 0,
        categoryId: null,
      })
    },
    deleteAttachment: (state, action) => {
      const { index } = action.payload
      state.currentRequest.attachments.splice(index, 1)
    },
    deleteTimeBlock: (state, action) => {
      const { index } = action.payload
      state.currentRequest.timeBlocks.splice(index, 1)
    },
    addAttachment: (state, action) => {
      state.currentRequest.attachments.push(action.payload)
    },
    removeAttachment: (state, action) => {
      state.currentRequest.attachments =
        state.currentRequest.attachments.filter(
          (file) => file.file !== action.payload.file
        )
    },
    changeOnlyNew: (state, action) => {
      state.recommended.filters.onlyNew = action.payload
      state.recommended.currentPage = 0
    },
    updatePDRequest: (state, action) => {
      const { field, value } = action.payload

      if (field === "title") {
        state.currentRequest.title = value
      }
      if (field === "description") {
        state.currentRequest.description = value
      }
      if (field === "startDate") {
        state.currentRequest.startDate = value
        state.currentRequest.endDate = value
      }
      if (field === "startDateTime") {
        state.currentRequest.startDateTime = value

        if (moment(value).isValid()) {
          if (
            moment(state.currentRequest.endDateTime).isBefore(moment(value))
          ) {
            state.currentRequest.endDateTime = moment(value)
              .add(1, "hour")
              .format()
          }
        }
      }

      if (field === "endDate") {
        state.currentRequest.endDate = value
      }
      if (field === "endDateTime") {
        state.currentRequest.endDateTime = value
      }
      if (field === "rating") {
        state.currentRequest.rating = value
      }
      if (field === "comments") {
        state.currentRequest.comments = value
      }

      // If we're changing the start and end times of the event
      // We'll also update the total available time as expressed in hours / minutes
      if (field === "startDate" || field === "endDate") {
        const totalMinutes = moment(state.currentRequest.endDate).diff(
          moment(state.currentRequest.startDate),
          "minutes"
        )

        state.currentRequest.totalAvailableTime.hours =
          totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0
        state.currentRequest.totalAvailableTime.minutes = totalMinutes % 60
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExternalPDRequests.fulfilled, (state, action) => {
      const { external_user_event_data, total_records } = action.payload

      external_user_event_data.forEach((event: any) => {
        event.field_end_date_value = moment
          .utc(event.field_end_date_value)
          .local()
          .format()
        event.field_start_date_value = moment
          .utc(event.field_start_date_value)
          .local()
          .format()
      })

      if (action.meta?.arg?.status === "accepted") {
        const newData =
          state.approved.currentPage > 0
            ? state.approved.data.concat(external_user_event_data)
            : external_user_event_data
        state.approved.data = _.orderBy(
          newData,
          (event: any) => event.changed,
          "desc"
        )

        state.approved.total_records = total_records
        state.approved.totalPages = Math.ceil(total_records / 25)
      } else {
        state.unapproved.data = _.orderBy(
          external_user_event_data,
          (event: any) => event.created,
          "desc"
        )
      }

      state.unapproved.fetched = true
    })

    builder.addCase(increaseRecommendedPage.pending, (state) => {
      state.recommended.currentPage++
      state.recommended.isFetchingMore = true
    })
    builder.addCase(increaseAcceptedPage.pending, (state) => {
      state.approved.currentPage++
      state.approved.isFetchingMore = true
    })

    builder.addCase(fetchRecommendedEvents.fulfilled, (state, action) => {
      state.recommended.fetched = true
      state.recommended.isFetchingMore = false
      state.recommended.data = state.recommended.data.concat(
        action.payload.data
      )
      state.recommended.totalPages = action.payload.totalPages
    })

    builder.addCase(getPreApprovalForms.fulfilled, (state, action) => {
      state.preApprovalForms.fetched = true
      state.preApprovalForms.data = action.payload.filter(
        (form: any) => !form.attributes.field_archive
      )
    })

    builder.addCase(saveExternalRequest.pending, (state, action) => {
      state.currentRequest.isSaving = true
    })
    builder.addCase(saveExternalRequest.fulfilled, (state, action) => {
      state.currentRequest.isSaving = false
      state.currentRequest = initialState.currentRequest
    })
    builder.addCase(saveUserPreApprovalForm.pending, (state, action) => {
      state.currentRequest.isSaving = true
    })
    builder.addCase(saveUserPreApprovalForm.fulfilled, (state, action) => {
      state.currentRequest.isSaving = false
    })
    builder.addCase(deleteExternalRequest.pending, (state, action) => {
      state.unapproved.data = state.unapproved.data.filter(
        (event) => event.id !== action.meta.arg.id
      )
    })
  },
})

export const {
  editRequest,
  startNewRequest,
  claimRecommended,
  toggleRequestModal,
  addTimeBlock,
  deleteTimeBlock,
  deleteAttachment,
  updateTimeBlock,
  updatePDRequest,
  addAttachment,
  removeAttachment,
  changeOnlyNew,
  setPreApprovalNeeded,
  selectPreApprovalForm,
} = externalPDSlice.actions

export default externalPDSlice.reducer
