import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const getCourseAuthors = createAsyncThunk(
  "exploreSlice/getCourseAuthors",
  async (search, thunkAPI) => {
    const { session } = thunkAPI.getState()

    const isOrgLevel =
      session.orgRoles.includes("organization-admin") ||
      session.orgRoles.includes("organization-drafter") ||
      session.orgRoles.includes("organization-creator") ||
      session.orgRoles.includes("organization-scheduler") ||
      session.orgRoles.includes("organization-observer") ||
      session.orgRoles.includes("organization-observation_admin")

    const orgId = session.group.id[0].value

    let users = []

    if (search) {
      let query = {
        search,
      }
      query["role[0]"] = "organization-admin"
      query["role[1]"] = "organization-creator"
      query["role[2]"] = "group-admin"
      query["role[3]"] = "group-creator"

      let response
      let allSubGroupIds = []

      // If they aren't org level, we've got to go through all of their subgroups
      if (!isOrgLevel) {
        allSubGroupIds = session.subgroups.data.map(
          (subgroup) => subgroup.drupal_internal__id
        )

        // We have to do a while loop and throw all results into an array here
        let i = 0
        while (i < allSubGroupIds.length) {
          response = await fetchWrapper.get(
            "/api/organization-members/" +
              allSubGroupIds[i] +
              "?" +
              qs.stringify(query)
          )

          if (response.ok) {
            let data = await response.json()

            const baseArray = !data.rows.content ? data.rows : []
            // eslint-disable-next-line no-loop-func
            baseArray.forEach((fetchedUser) => {
              fetchedUser.id = fetchedUser.uuid

              if (!users.find((existing) => existing.uid === fetchedUser.uid)) {
                users.push(fetchedUser)
              }
            })
          }
          i++
        }
      }
      // Otherwise just fetch all users in the org
      else {
        response = await fetchWrapper.get(
          "/api/organization-members/" + orgId + "?" + qs.stringify(query)
        )

        if (response.ok) {
          let data = await response.json()

          if (data.rows.content) {
            data.rows = []
          } else {
            data.rows.forEach((user) => {
              user.id = user.uuid
            })
          }

          users = data.rows
        }
      }

      return users
    }
  }
)
export const debouncedGetCourseAuthors = debounceThunk(getCourseAuthors, 750)

export const getExploreContent = createAsyncThunk(
  "exploreSlice/getExploreContent",
  async (args, thunkAPI) => {
    const { filters, currentPage } = thunkAPI.getState().explore
    const { isPartner } = thunkAPI.getState().session
    const { tags } = thunkAPI.getState()

    const url = isPartner
      ? "/api/mm_partner_portal/course_explore?"
      : "/api/course_entity/explore?"
    let query = {
      search: filters.searchQuery,
      status: filters.status,

      difficulty: filters.difficulty.toLowerCase(),
      lp: filters.learningPath,
      // Hide excluded items
      excluded: 1,
      // Only show released items
      released: 1,
      page: {
        offset: 25 * currentPage,
      },
    }

    if (filters.author_id) {
      // @todo - need to make sure Taylor set up this filter and find out the exact syntax
      query.author_id = filters.author_id
    }

    // Handle tags
    let currentTagIds = []

    if (filters.selectedTags.length) {
      filters.selectedTags.forEach((tag) => {
        let fullTag = tags.data.find((full) => full.id === tag.id)
        currentTagIds.push(fullTag.attributes.drupal_internal__tid)
      })
      query.tags = currentTagIds.join()
    }

    if (filters.category) {
      query["category[0]"] = filters.category.attributes.drupal_internal__tid
    }

    if (
      filters.source &&
      filters.source !== "any" &&
      filters.source !== "mobilemind"
    ) {
      query.org = "my_org"
    } else {
      query.org = filters.source
    }
    if (filters.remainingOnly) {
      query.status = "not_started"
    }

    if (args?.reset) {
      query = {
        page: {
          offset: 0,
        },
        status: "not_started",
      }
    }

    let courseFetch = await fetchWrapper.get(url + qs.stringify(query))
    let fetchedCourses = await courseFetch.json()

    return {
      courses: fetchedCourses.data,
      total: fetchedCourses.total_records,
    }
  }
)

const debouncedGetExploreContent = debounceThunk(getExploreContent, 750)

export const updateExploreFilters = createAsyncThunk(
  "exploreSlice/updateExploreFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetExploreContent(args))
    return args
  }
)

export const resetExploreFilters = createAsyncThunk(
  "exploreSlice/resetExploreFilters",
  async (args, thunkAPI) => {
    const filters = {
      searchQuery: "",
      category: null,
      selectedTags: [],
      learningPath: "any",
      source: "any",
      difficulty: "any",
      courseAuthorSearch: "",
      courseAuthorResults: [],
      author_id: null,
      remainingOnly: true,
    }

    thunkAPI.dispatch(getExploreContent({ reset: true }))
    return filters
  }
)

export const exploreSlice = createSlice({
  name: "exploreSlice",
  initialState: {
    courses: [],
    assessments: [],
    currentPage: 0,
    totalPages: 0,
    isFetching: true,
    courseAuthorLoading: false,
    filters: {
      selectedTags: [],
      searchQuery: "",
      category: null,
      learningPath: "any",
      source: "any",
      difficulty: "any",
      courseAuthorSearch: "",
      courseAuthorResults: [],
      author_id: null,
      remainingOnly: true,
    },
  },
  reducers: {
    increaseCurrentPage: (state) => {
      state.currentPage++
    },
    filterLearningPathCourses: (state, action) => {
      state.filters[action.payload.name] = action.payload.value
    },
  },
  extraReducers: {
    [getCourseAuthors.pending]: (state, action) => {
      state.filters.courseAuthorSearch = action.meta.arg
      state.courseAuthorLoading = true
    },
    [getCourseAuthors.fulfilled]: (state, action) => {
      state.filters.courseAuthorResults = action.payload
      state.courseAuthorLoading = false
    },

    [updateExploreFilters.fulfilled]: (state, action) => {
      state.isFetching = true

      if (action.payload.name !== "remainingOnly") {
        state.isFetching = true
      }
      if (action.payload.name === "tags") {
        if (action.payload.method === "add") {
          state.filters.selectedTags.push(action.payload.value)
        } else {
          state.filters.selectedTags.splice(action.payload.value, 1)
        }
      }
      if (action.payload === "increaseCurrentPage") {
        state.currentPage++
      } else {
        state.courses = []
        state.currentPage = 0
        state.totalPages = 0
      }

      state.totalPages = 0
      state.filters[action.payload.name] = action.payload.value
    },
    [resetExploreFilters.pending]: (state) => {
      state.isFetching = true
      state.courses = []
    },
    [resetExploreFilters.fulfilled]: (state, action) => {
      state.currentPage = 0
      state.filters = action.payload
    },
    [getExploreContent.fulfilled]: (state, action) => {
      state.hasFetched = true
      state.isFetching = false
      state.courses = state.currentPage
        ? state.courses.concat(action.payload.courses)
        : action.payload.courses
      state.totalPages = Math.ceil(action.payload.total / 50)
      state.total = action.payload.total
    },
  },
})

export const { increaseCurrentPage, filterLearningPathCourses } =
  exploreSlice.actions

export default exploreSlice.reducer
