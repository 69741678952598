import { useState } from "react"
import UserInitial from "./UserInitial"
import ButtonSmall from "./ButtonSmall"
import he from "he"
import classNames from "classnames"
import "../styles/index.scss"

interface UserLabelProps {
  imagePath?: string
  firstName: string
  lastName: string
  subgroups: string
  isReviewCard?: boolean
}

const UserLabel = ({
  imagePath,
  firstName,
  lastName,
  subgroups,
  isReviewCard,
}: UserLabelProps) => {
  const userImageStyle = {
    width: 34,
    flexShrink: 0,
    height: 34,
    backgroundImage: "url(" + imagePath + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 40,
    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 30%)",
    marginRight: 10,
    border: "3px solid white",
  }

  const userSubGroups = subgroups ? subgroups.split(",") : ""
  const [hover, setHover] = useState(false)

  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: 50 }}>
      {imagePath && !imagePath.includes("default_temp") ? (
        <div
          className="user-label-user-image-container user-has-image"
          style={userImageStyle}
        />
      ) : (
        <div className="user-label-user-image-container">
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <UserInitial
              containerStyle={{ marginRight: 10 }}
              fontSize={15}
              size={40}
              initials={
                (firstName && firstName.charAt(0).toUpperCase()) +
                (lastName && lastName.charAt(0).toUpperCase())
              }
            />
          }
        </div>
      )}
      <div style={{ position: "relative", height: 43 }}>
        <strong
          className={classNames(
            "user-label-user-name",
            userSubGroups?.length > 1 && "multi-group",
            !userSubGroups?.length && "zero-group"
          )}
          style={{
            display: "block",
            textTransform: "capitalize",
            fontSize: 16,
            position: "relative",
            bottom: userSubGroups.length > 1 ? -5 : -7,
          }}
        >
          {firstName && firstName.toLowerCase()}{" "}
          {lastName && lastName.toLowerCase()}
        </strong>
        <div
          style={{
            fontWeight: "normal",
            display: "flex",
            alignItems: "center",
            marginTop: userSubGroups.length > 1 && !isReviewCard ? -5 : 5,
          }}
          className="user-label-user-subgroup BLHhehehelahelkhwehw ekh"
        >
          <span
            className={classNames(
              userSubGroups && userSubGroups.length > 1 && "multi-group-name"
            )}
            style={{
              color: "rgba(0, 0, 0, 0.87)",
              opacity: 0.7,
              fontSize: 10,
              textTransform: "uppercase",
            }}
          >
            {" "}
            {subgroups && userSubGroups && userSubGroups[0]}
          </span>

          {userSubGroups && userSubGroups.length > 1 && (
            <div className="button-subgroups">
              <div
                className={classNames("subgroups-popover", hover && "hover")}
              >
                <ul>
                  {userSubGroups &&
                    userSubGroups.map((group: string) => {
                      return (
                        <li
                          style={{ fontSize: 12, fontWeight: "bold" }}
                          key={group}
                        >
                          <span className="icon school" />
                          {he.decode(group)}
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          )}
          {userSubGroups && userSubGroups.length > 1 && (
            <div
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <ButtonSmall>+{userSubGroups.length - 1}</ButtonSmall>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserLabel
