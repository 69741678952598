import { MaintenanceModeMessage } from "@mobilemind/common/src/components/MaintenanceModeMessage"
import theme from "@mobilemind/common/src/theme/theme"
import { Button } from "@mui/material"
import { useSelector } from "react-redux"
import { selectMaintenanceMode } from "../store/reducers/session"
import "../styles/login.scss"

function LoginLayout() {
  const maintenanceMode = useSelector(selectMaintenanceMode)
  return (
    <div
      style={{ backgroundColor: theme.palette.secondary.main }}
      className="page login"
    >
      <div
        style={{
          backgroundImage: "url(" + theme.mixins.images.background + ")",
        }}
        className="backgroundWallpaper"
      />

      <div
        style={{
          background:
            "linear-gradient(120deg, " +
            theme.palette.secondary.main +
            " 0%, " +
            theme.palette.primary.main +
            " 70%, " +
            theme.palette.primary.main +
            " 100%)",
        }}
        className="background"
      />

      <div className="loginWrapper">
        <div className="flexRow">
          <img src={theme.mixins.images.logo} alt="MobileMind" />
        </div>

        {maintenanceMode ? (
          <MaintenanceModeMessage />
        ) : (
          <>
            <div className="flexRow">
              <Button
                className="button login"
                href={`${process.env.REACT_APP_API_URL}/user/login/google`}
              >
                <span
                  className="icon google official"
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
                Sign in with Google
              </Button>
            </div>

            <div className="flexRow">
              <Button
                className="button login microsoft"
                href={`${process.env.REACT_APP_API_URL}/user/login/microsoft`}
              >
                <span
                  className="icon microsoft"
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
                Sign in with Microsoft
              </Button>
            </div>
            {theme.accountHref && (
              <section style={{ marginTop: -10, fontSize: 14 }}>
                <p>
                  Not a subscriber?{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: theme.palette.primary.main }}
                    href={theme.accountHref}
                  >
                    <strong>Learn more here.</strong>
                  </a>
                </p>
              </section>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default LoginLayout
