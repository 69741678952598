import { generateTimestamp } from "@mobilemind/common/src/functions"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"

export function LearningBuddyStatus({
  mailToString,
  assessment,
  activeCourse,
  bummer,
  encouragement,
  congrats,
  canRetakeOutOfCompliance,
  outOfComplianceTraining,
}) {
  const supportEmail =
    activeCourse.attributes?.field_support_email &&
    activeCourse.attributes.field_support_email[0]
  return (
    <section>
      {assessment.attributes.field_status === "in_progress" && (
        <>
          {assessment.attributes.field_attempts >= 2 && mailToString ? (
            <a href={mailToString} className="getHelp">
              <LearningBuddyMessage
                message={
                  <div style={{ marginTop: 5, lineHeight: 1.2 }}>
                    <span style={{ fontWeight: "normal" }}>
                      Are you having trouble with this challenge?{" "}
                      {supportEmail ? (
                        <>
                          Reach out to <strong>{supportEmail}</strong> for help.
                        </>
                      ) : (
                        <strong>Click me to get help.</strong>
                      )}
                    </span>
                  </div>
                }
              />
            </a>
          ) : (
            <>
              {activeCourse.attributes.field_submission_type === "Quiz" ? (
                <>
                  {assessment.attributes.field_quiz_score &&
                  assessment.quizAnswers ? (
                    <LearningBuddyMessage
                      message={
                        <>
                          <strong>{bummer}</strong> You didn't pass this quiz.
                          Ready to try again?
                        </>
                      }
                    />
                  ) : (
                    <LearningBuddyMessage
                      message={
                        <>
                          You started this course{" "}
                          {generateTimestamp(
                            assessment.attributes.created
                          ).toLowerCase()}
                          .
                        </>
                      }
                    />
                  )}
                </>
              ) : (
                <>
                  {assessment.attributes.field_comments ? (
                    <LearningBuddyMessage
                      message={
                        <>You've received some feedback on your submission.</>
                      }
                    />
                  ) : (
                    <LearningBuddyMessage
                      message={
                        <>
                          You started this course{" "}
                          {generateTimestamp(
                            assessment.attributes.created
                          ).toLowerCase()}
                          .
                        </>
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {assessment.attributes.field_status === "submitted" && (
        <LearningBuddyMessage
          message={
            <>
              <strong>{congrats}</strong> You submitted this course{" "}
              {generateTimestamp(assessment.attributes.changed).toLowerCase()}.
            </>
          }
        />
      )}

      {assessment.attributes.field_status === "completed" && (
        <>
          {canRetakeOutOfCompliance ? (
            <LearningBuddyMessage
              message={
                <>
                  This course is part of{" "}
                  <strong>{outOfComplianceTraining.name}</strong> and is
                  mandated.
                </>
              }
            />
          ) : (
            <LearningBuddyMessage
              message={
                <>
                  You completed this course{" "}
                  {generateTimestamp(
                    assessment.attributes.field_completed_date
                  ).toLowerCase()}
                  . <strong>{encouragement}</strong>
                </>
              }
            />
          )}
        </>
      )}
    </section>
  )
}
