import Loading from "@mobilemind/common/src/components/Loading"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../../store/store"

import {
  fetchExternalPDRequests,
  increaseAcceptedPage,
} from "../../../store/reducers/externalPD"
import UnapprovedCard from "./UnapprovedCard"
import BottomScrollListener from "react-bottom-scroll-listener"

interface PendingProps {
  status: string
}

const ExternalRequestContainer = ({ status }: PendingProps) => {
  const { unapproved, approved } = useSelector(
    (state: RootState) => state.externalPD
  )

  const requestListBase = approved.data.concat(unapproved.data)

  let requestList = requestListBase.filter((event: any) => {
    if (status === "pending") {
      return (
        event.field_status_value === "awaiting_pre_appr" ||
        event.field_status_value === "pre_appr_granted" ||
        event.field_status_value === "pending"
      )
    }
    if (status === "declined") {
      return (
        event.field_status_value === "declined" ||
        event.field_status_value === "pre_approval_declined"
      )
    }

    return event.field_status_value === status
  })

  if (status === "accepted") {
    requestList = approved.data
  }
  const [activeItem, setActiveItem] = useState("")
  const dispatch = useAppDispatch()

  useEffect(() => {
    !unapproved.fetched && dispatch(fetchExternalPDRequests({}))
    !approved.fetched &&
      dispatch(fetchExternalPDRequests({ status: "accepted" }))
  }, [unapproved.fetched, approved.fetched, dispatch])

  function handleScroll() {
    if (status === "accepted") {
      if (approved.currentPage < approved.totalPages - 1) {
        dispatch(
          increaseAcceptedPage({
            status: "accepted",
          })
        )
      }
    }
  }

  return (
    <BottomScrollListener onBottom={handleScroll}>
      <div className="page events-layout recommended-events">
        <h2>
          <div style={{ flex: 1 }}>{status} Requests</div>
          {!unapproved.fetched && (
            <Loading message={"Getting " + { status } + " requests..."} />
          )}
        </h2>
        <div className="pending-list">
          <ul>
            {requestList.map((event: any, index: number) => {
              const isActive = activeItem === event.id
              return (
                <UnapprovedCard
                  key={index}
                  activeItem={activeItem}
                  isActive={isActive}
                  onClick={(clicked) => {
                    clicked.id === activeItem
                      ? setActiveItem("")
                      : setActiveItem(clicked.id)
                  }}
                  animationDelay={index}
                  event={event}
                />
              )
            })}
            {requestList.length < 1 && (
              <em>You don't have any {status} requests.</em>
            )}
          </ul>
        </div>
      </div>
    </BottomScrollListener>
  )
}

export default ExternalRequestContainer
