import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import moment from "moment"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../../store/store"
import he from "he"
import {
  Box,
  Button,
  ButtonBase,
  Stack,
  Step,
  Stepper,
  Tab,
  Typography,
} from "@mui/material"
import ReviewerQuote from "@mobilemind/common/src/components/ReviewerQuote"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import RatingStar from "@mobilemind/common/src/icons/RatingStar"
import classNames from "classnames"
import { useEffect, useState } from "react"
import {
  deleteExternalRequest,
  editRequest,
  getSingleExternalRequest,
} from "../../../store/reducers/externalPD"
import Loading from "@mobilemind/common/src/components/Loading"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import InlineBuddyMessage from "../../learningBuddy/InlineBuddyMessage"

import WorkingTime from "../../../img/working-time.svg"
import pluralize from "pluralize"

interface CapsuleProps {
  animationDelay?: number
  event?: any
  activeItem?: string
  onClick?: (event: any) => void
  preview?: boolean
  isActive?: boolean
}

type SingleRequestProps = {
  time_remaining: string
  pre_appr_form_data: {
    questions?: {
      id: string
      bundle: string
      field_question_name: string
      field_required: string
      user_response?: string
      field_answer_op?: string
    }[]
    user_appr_steps: any[]
  }
}

const UnapprovedCard = ({
  animationDelay,
  event,
  activeItem,
  onClick,
  preview,
  isActive,
}: CapsuleProps) => {
  const dispatch = useAppDispatch()

  const [confirmDelete, setConfirmDelete] = useState(false)
  const totalMinutes = Number(event.field_total_time_value)
  const hours = convertMinutesToHoursMinutes(totalMinutes).hours
  const minutes = convertMinutesToHoursMinutes(totalMinutes).minutes

  const isDeclined = event.field_status_value === "declined"
  const isPending = event.field_status_value === "pending"

  const isPreApprovedRequest = event.user_pa_form_uuid && true

  const { categories, session } = useSelector((state: RootState) => state)
  const [isLoading, setIsLoading] = useState(false)

  const [singleRequestResult, setSingleRequestResult] =
    useState<SingleRequestProps | null>(null)

  const [currentTab, setCurrentTab] = useState(0)

  const preApprovalSteps =
    singleRequestResult?.pre_appr_form_data?.user_appr_steps ?? []

  let furthestStep = 0

  preApprovalSteps.forEach((step: any, index: number) => {
    if (step.status === "approved") {
      furthestStep++
    }
  })

  const [currentApprovalStep, setCurrentApprovalStep] = useState(0)

  const isAwaitingPreApproval = event.field_status_value === "awaiting_pre_appr"
  const isPreApprovalDeclined =
    event.field_status_value === "pre_approval_declined"

  useEffect(() => {
    if (isActive && !preview) {
      if (!singleRequestResult && !isLoading) {
        setIsLoading(true)
        dispatch(
          getSingleExternalRequest({ event_id: event.drupal_internal__id })
        ).then((result: any) => {
          setSingleRequestResult(result.payload)
          if (result.payload?.pre_appr_form_data?.user_appr_steps) {
            // Find the first step that is pending
            const firstPendingStepIndex =
              result.payload.pre_appr_form_data?.user_appr_steps.findIndex(
                (step: any) => {
                  return step.status === "pending"
                }
              )
            const firstDeclinedStepIndex =
              result.payload.pre_appr_form_data?.user_appr_steps.findIndex(
                (step: any) => {
                  return step.status === "denied"
                }
              )

            // Figure out if we need to start on the current pending step, the declined step, or the last step
            let activeApprovalStep = 0
            if (event.field_status_value === "awaiting_pre_appr") {
              activeApprovalStep = firstPendingStepIndex
            } else if (event.field_status_value === "pre_approval_declined") {
              activeApprovalStep = firstDeclinedStepIndex
            } else if (
              event.field_status_value === "pre_appr_granted" ||
              isPending
            ) {
              activeApprovalStep =
                result.payload.pre_appr_form_data?.user_appr_steps.length - 1
            }

            // Default to Request Details if one of the below statuses
            if (
              event.field_status_value === "pending" ||
              event.field_status_value === "accepted" ||
              event.field_status_value === "declined"
            ) {
              setCurrentTab(1)
            }
            setCurrentApprovalStep(activeApprovalStep)
          }

          setIsLoading(false)
        })
      }
    } else {
      setSingleRequestResult(null)
      setIsLoading(false)
    }
  }, [
    isActive,
    preview,
    activeItem,
    isPending,
    event.drupal_internal__id,
    event.field_status_value,
    event.id,
    isLoading,
    singleRequestResult,
    dispatch,
  ])

  let timestamp =
    isPending || isAwaitingPreApproval
      ? moment.unix(event.created).format("YYYY-MM-DDTHH:mm:ss")
      : event.field_reviewed_date_value

  const preApprovalGranted = event.field_status_value === "pre_appr_granted"
  const preApprovalDeclined =
    event.field_status_value === "pre_approval_declined"

  let preApprovalLabelColor = preApprovalDeclined ? "#F56B6B" : "#C9F03D"
  if (preApprovalGranted) {
    preApprovalLabelColor = "rgb(36, 220, 145)"
  }

  return (
    <li
      className="request-list-item"
      style={{ animationDelay: animationDelay + "s" }}
    >
      <Accordion
        onChange={() => {
          onClick && onClick(event)
        }}
        expanded={isActive}
      >
        <AccordionSummary>
          <div className="flexRow">
            <span className="icon external-pd color" />

            <div style={{ flex: 1, marginLeft: 10 }}>
              <h3>{event.title}</h3>
              <span className="timestamp">
                {moment(event.field_start_date_value).format("MMMM Do, YYYY")}
              </span>
            </div>
            {(preApprovalDeclined ||
              isAwaitingPreApproval ||
              preApprovalGranted) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  paddingInline: 3,
                  backgroundColor: preApprovalLabelColor,
                  color: preApprovalDeclined ? "white" : "black",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: 10,
                  borderRadius: 2,
                  marginRight:
                    preApprovalDeclined || preApprovalGranted ? 0 : 4,
                }}
              >
                {preApprovalDeclined && <>Pre-Approval Declined</>}
                {isAwaitingPreApproval &&
                  !preApprovalDeclined &&
                  !preApprovalGranted && <>Awaiting Pre-Approval</>}
                {preApprovalGranted && <>Pre-Approval Granted</>}
              </Box>
            )}
            {!isAwaitingPreApproval &&
              !preApprovalDeclined &&
              !preApprovalGranted && (
                <TimeCapsule hours={hours} minutes={minutes} />
              )}

            {!preview && (
              <>
                {(isPending || isAwaitingPreApproval) && !isDeclined && (
                  <span className="icon raised-hand" />
                )}
                {isDeclined && <span className="icon declined" />}
                <strong
                  className="requested-time"
                  style={{ color: isDeclined ? "#EB5252" : "black" }}
                >
                  {isDeclined && <>Declined </>}
                  {timestamp && moment(timestamp).fromNow()}
                </strong>
              </>
            )}
          </div>
        </AccordionSummary>

        <AccordionDetails>
          <Box
            sx={{
              paddingTop: 4,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!isLoading && isPreApprovedRequest && (
              <TabsCapsule
                value={currentTab}
                onChange={(event, newTab) => setCurrentTab(newTab)}
              >
                <Tab value={0} label={"Pre-Approval"} />
                <Tab
                  value={1}
                  disabled={isAwaitingPreApproval}
                  label={"Request Details"}
                />
              </TabsCapsule>
            )}
          </Box>

          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: 4,
              }}
            >
              <Loading message="Getting request..." />
            </Box>
          )}

          {!isLoading && (
            <>
              {/** Pre-Approval Info */}
              {currentTab === 0 && isPreApprovedRequest && (
                <Box>
                  <Box sx={{ paddingInline: 4 }}>
                    {preApprovalGranted && (
                      <Box
                        className="buddy-approval-container"
                        sx={{
                          marginBlock: 3,
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                        }}
                      >
                        <InlineBuddyMessage
                          // @ts-ignore
                          message={
                            <span>
                              <strong>
                                Your request has been pre-approved!
                              </strong>
                              <br />
                              Click <strong>Continue</strong> to upload your
                              certificate after attending your event.
                            </span>
                          }
                        />
                      </Box>
                    )}

                    <header>Your Responses</header>

                    {singleRequestResult &&
                      singleRequestResult.pre_appr_form_data.questions?.map(
                        (question, index) => {
                          return (
                            <section
                              style={{ marginBlock: 15 }}
                              key={index}
                              className="pre-approval-item"
                            >
                              <strong style={{ fontSize: 18 }}>
                                {question.field_question_name}
                              </strong>

                              {question.bundle === "open_ended_questions" && (
                                <p style={{ marginTop: 5 }}>
                                  {question.user_response}
                                </p>
                              )}

                              {question.bundle === "fixed_choice_questions" && (
                                <ul style={{ marginTop: 5, paddingLeft: 15 }}>
                                  {question.user_response
                                    ?.split(",")
                                    .map((res) => {
                                      return (
                                        <li
                                          style={{
                                            listStyleType: "disc",
                                            fontSize: 14,
                                          }}
                                        >
                                          {res}
                                        </li>
                                      )
                                    })}
                                </ul>
                              )}

                              {question.bundle === "date_question" &&
                                question.user_response && (
                                  <p style={{ marginTop: 5 }}>
                                    {moment(question.user_response).format(
                                      "MMMM Do, YYYY"
                                    )}
                                  </p>
                                )}
                            </section>
                          )
                        }
                      )}
                  </Box>

                  <Box
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                      padding: 4,
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: 1.5,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <strong>
                        Approval Step {currentApprovalStep + 1}:{" "}
                        {preApprovalSteps[currentApprovalStep]?.step_name}
                      </strong>
                    </Box>

                    {preApprovalSteps.length > 0 && (
                      <Box
                        className="approval-stepper"
                        style={{
                          margin: "0 auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Stepper activeStep={currentApprovalStep}>
                          {preApprovalSteps.map(
                            (step: any, stepIndex: number) => {
                              return (
                                <Step key={2}>
                                  <ButtonBase
                                    disabled={stepIndex > furthestStep}
                                    onClick={() => {
                                      setCurrentApprovalStep(stepIndex)
                                    }}
                                    sx={(theme) => ({
                                      width: 30,
                                      height: 30,
                                      borderRadius: 2,
                                      backgroundColor:
                                        step.status === "denied"
                                          ? "#F56B6B !important"
                                          : theme.palette.secondary.main +
                                            " !important",
                                      opacity:
                                        stepIndex > furthestStep ? 0.5 : 1,
                                      border:
                                        currentApprovalStep === stepIndex
                                          ? "2px solid #000"
                                          : "none",
                                    })}
                                  >
                                    {stepIndex > furthestStep - 1 &&
                                      step.status === "pending" && (
                                        <strong>{stepIndex + 1}</strong>
                                      )}
                                    {step.status === "approved" && (
                                      <span
                                        style={{ width: 15, height: 15 }}
                                        className="icon check"
                                      />
                                    )}
                                    {step.status === "denied" && (
                                      <span
                                        style={{ width: 20, height: 20 }}
                                        className="icon denied"
                                      />
                                    )}
                                  </ButtonBase>
                                </Step>
                              )
                            }
                          )}
                        </Stepper>
                      </Box>
                    )}

                    <Box sx={{ marginTop: 5 }}>
                      {preApprovalSteps.length > 0 &&
                        preApprovalSteps.map((step: any, stepIndex: number) => {
                          if (currentApprovalStep === stepIndex) {
                            return (
                              <Box key={stepIndex}>
                                {step.status !== "approved" &&
                                  step.status !== "denied" && (
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={WorkingTime}
                                        width={50}
                                        height={50}
                                        alt="Awaiting Feedback"
                                      />

                                      <Box
                                        style={{
                                          fontSize: 12,
                                          backgroundColor: "#ebebeb",
                                          borderRadius: 15,
                                          marginTop: 10,
                                          padding: "0px 10px",
                                          height: 25,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Estimated Time Remaining:{" "}
                                        <strong
                                          style={{
                                            display: "inline-block",
                                            marginLeft: 3,
                                          }}
                                        >
                                          {Number(
                                            singleRequestResult?.time_remaining
                                          )}{" "}
                                          {pluralize(
                                            "day",
                                            Number(
                                              singleRequestResult?.time_remaining
                                            )
                                          )}
                                        </strong>
                                      </Box>
                                    </Box>
                                  )}

                                {(step.status === "approved" ||
                                  step.status === "denied") && (
                                  <>
                                    <strong
                                      style={{ fontSize: 12, opacity: 0.8 }}
                                    >
                                      {step.status === "approved" ? (
                                        <>Approved</>
                                      ) : (
                                        <>Declined</>
                                      )}{" "}
                                      {moment(step.decision_date).fromNow()}
                                    </strong>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        sx={{
                                          flexShrink: 0,
                                          alignItems: "center",
                                          marginBottom: "15px!important",
                                          marginLeft: "0!important",
                                          paddingRight: 6,
                                          marginTop: 2,
                                        }}
                                        spacing={2}
                                      >
                                        <UserInitial
                                          fontSize={16}
                                          size={40}
                                          initials={
                                            step.appr_first_name?.charAt(0) +
                                            step.appr_last_name?.charAt(0)
                                          }
                                        />
                                        <Box>
                                          <Typography
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            {step.appr_first_name}{" "}
                                            {step.appr_last_name}
                                          </Typography>

                                          <Typography
                                            sx={{
                                              opacity: 0.6,
                                              textTransform: "uppercase",
                                              fontSize: "12px !important",
                                            }}
                                          >
                                            {step.approver_subgroup.join(", ")}
                                          </Typography>
                                        </Box>
                                      </Stack>

                                      <Box
                                        className="approval-notes"
                                        sx={{
                                          padding: 2,
                                          borderRadius: 0.8,
                                          marginTop: 1,
                                          backgroundColor: "rgb(189, 245, 222)",
                                        }}
                                      >
                                        <div className="triangle" />
                                        <em>{step.notes}</em>
                                      </Box>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            )
                          }
                          return null
                        })}
                    </Box>

                    {(isAwaitingPreApproval ||
                      preApprovalGranted ||
                      isPreApprovalDeclined) && (
                      <footer
                        className="flexRow"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {!confirmDelete ? (
                          <>
                            <Button
                              className="button secondary large"
                              onClick={() => {
                                setConfirmDelete(true)
                              }}
                            >
                              <span className="icon delete" />
                              Delete
                            </Button>
                            {!isDeclined && !isPreApprovalDeclined && (
                              <ButtonLarge
                                style={{
                                  pointerEvents: !preApprovalGranted
                                    ? "none"
                                    : "auto",
                                  opacity: !preApprovalGranted ? 0.5 : 1,
                                }}
                                onClick={() => {
                                  dispatch(editRequest(event))
                                }}
                              >
                                Continue
                              </ButtonLarge>
                            )}
                          </>
                        ) : (
                          <>
                            <em className="prompt">
                              Are you sure you want to delete this request?
                            </em>
                            <Button
                              className="button secondary large delete"
                              onClick={() => {
                                setConfirmDelete(false)
                              }}
                            >
                              Cancel
                            </Button>
                            <ButtonLarge
                              style={{
                                backgroundColor: "#F56B6B",
                                color: "white",
                              }}
                              onClick={() => {
                                setConfirmDelete(false)
                                dispatch(deleteExternalRequest(event))
                              }}
                            >
                              Delete
                            </ButtonLarge>
                          </>
                        )}
                      </footer>
                    )}
                  </Box>
                </Box>
              )}

              {(currentTab === 1 || !isPreApprovedRequest) && (
                <Box style={{ padding: 20 }}>
                  <div className="request-content">
                    <>
                      <div className="column">
                        <section>
                          <header>PD Categories</header>
                          <ul>
                            {event.timeBlocks &&
                              event.timeBlocks.map((block: any) => {
                                const category = categories.data.find(
                                  (cat: any) => cat.id === block.categoryId
                                )
                                if (category) {
                                  return (
                                    <li
                                      style={{
                                        padding: "5px 0",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        className="category-icon"
                                        style={{
                                          width: 30,
                                          height: 30,
                                          backgroundImage:
                                            "url(" +
                                            process.env.REACT_APP_API_URL +
                                            category.image.attributes.uri.url +
                                            ")",
                                        }}
                                      />
                                      <div style={{ flex: 1 }}>
                                        {category.attributes.name}
                                      </div>
                                      <TimeCapsule
                                        hours={block.hours}
                                        minutes={block.minutes}
                                      />
                                    </li>
                                  )
                                }
                                return null
                              })}
                          </ul>
                        </section>

                        <section className="ratingContainer">
                          <header>Your Rating</header>
                          <div className="flexRow">
                            {[1, 2, 3, 4, 5].map((value) => {
                              return (
                                <RatingStar
                                  key={value}
                                  selected={event.field_rating_value >= value}
                                />
                              )
                            })}
                          </div>
                        </section>
                        {event.field_learner_comment_value && (
                          <section>
                            <header>Comments</header>
                            <div className="flexRow">
                              <SanitizedHTML
                                html={event.field_learner_comment_value}
                              />
                            </div>
                          </section>
                        )}
                      </div>
                      <div className="column">
                        {event.description__value && (
                          <section>
                            <header>Description</header>
                            <SanitizedHTML html={event.description__value} />
                          </section>
                        )}

                        {event.attachments.length > 0 && (
                          <section>
                            <header>Your Attachments</header>
                            <ul className="pd-attachment-list">
                              {event.attachments.map((file: any) => {
                                return (
                                  <li key={file.filename}>
                                    {file.extension !== "png" &&
                                    file.extension !== "jpg" &&
                                    file.extension !== "jpeg" ? (
                                      <span
                                        className={classNames(
                                          "icon",
                                          file.extension
                                        )}
                                      />
                                    ) : (
                                      <span
                                        className={classNames("icon image")}
                                        style={{
                                          backgroundImage:
                                            "url(" + file.file + ")",
                                        }}
                                      />
                                    )}
                                    {decodeURIComponent(
                                      he.decode(file.filename)
                                    ).replaceAll("&#x202F;", " ")}{" "}
                                  </li>
                                )
                              })}
                            </ul>
                          </section>
                        )}

                        {event.rev_attachments_url?.length > 0 && (
                          <section>
                            <header>Reviewer Attachments</header>
                            <ul className="pd-attachment-list">
                              {event.rev_attachments_url.map((url: any) => {
                                const filename = url.replace(/^.*[\\/]/, "")
                                const extension = filename.split(".").pop()

                                return (
                                  <li key={url}>
                                    <a
                                      style={{
                                        color: "black",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      href={url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {extension !== "png" &&
                                      extension !== "jpg" &&
                                      extension !== "jpeg" ? (
                                        <span
                                          className={classNames(
                                            "icon",
                                            extension
                                          )}
                                        />
                                      ) : (
                                        <span
                                          className={classNames("icon image")}
                                          style={{
                                            backgroundImage: "url(" + url + ")",
                                          }}
                                        />
                                      )}
                                      {decodeURIComponent(
                                        he.decode(filename)
                                      ).replaceAll("&#x202F;", " ")}
                                    </a>
                                  </li>
                                )
                              })}
                            </ul>
                          </section>
                        )}
                      </div>
                    </>
                    <></>
                  </div>

                  {event.reviewer && event.field_rev_comment_value && (
                    <div className="reviewer-comments">
                      <ReviewerQuote
                        groupLabel={session.group.label[0].value}
                        imageSrc={event.reviewer.reviewer_pic}
                        firstName={event.reviewer.reviewer_first_name}
                        lastName={event.reviewer.reviewer_last_name}
                        text={event.field_rev_comment_value}
                      />
                    </div>
                  )}
                  {!preview && event.field_status_value !== "accepted" && (
                    <footer
                      className="flexRow"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {!confirmDelete ? (
                        <>
                          <Button
                            className="button secondary large"
                            onClick={() => {
                              setConfirmDelete(true)
                            }}
                          >
                            <span className="icon delete" />
                            Delete
                          </Button>
                          <ButtonLarge
                            onClick={() => {
                              dispatch(editRequest(event))
                            }}
                          >
                            {isDeclined ? (
                              <>
                                <span className="icon resubmit" />
                                Edit & Resubmit
                              </>
                            ) : (
                              <>Edit Request </>
                            )}
                          </ButtonLarge>
                        </>
                      ) : (
                        <>
                          <em className="prompt">
                            Are you sure you want to delete this request?
                          </em>
                          <Button
                            className="button secondary large delete"
                            onClick={() => {
                              setConfirmDelete(false)
                            }}
                          >
                            Cancel
                          </Button>
                          <ButtonLarge
                            style={{
                              backgroundColor: "#F56B6B",
                              color: "white",
                            }}
                            onClick={() => {
                              setConfirmDelete(false)
                              dispatch(deleteExternalRequest(event))
                            }}
                          >
                            Delete
                          </ButtonLarge>
                        </>
                      )}
                    </footer>
                  )}
                </Box>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </li>
  )
}

export default UnapprovedCard
