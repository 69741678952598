import { connect, useDispatch } from "react-redux"
import _ from "lodash"
import {
  TextField,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Box,
  Autocomplete,
} from "@mui/material"

import he from "he"

import ButtonSmall from "../../components/ButtonSmall"
import { isMobile } from "react-device-detect"
import classNames from "classnames"
import { isMMContentHidden } from "@mobilemind/common/src/functions"

import "../../styles/sidebar.scss"
import "../../styles/categories.scss"
import theme from "@mobilemind/common/src/theme/theme"
import TagSelect from "@mobilemind/common/src/components/TagSelect"

import {
  debouncedGetCourseAuthors,
  resetExploreFilters,
  updateExploreFilters,
} from "./exploreSlice"

import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({
  tags,
  session,
  categories,
  explore,
  sidebar,
  learningPaths,
}) => {
  return {
    tags,
    categories,
    filters: explore.filters,
    courseAuthorLoading: explore.courseAuthorLoading,
    session,
    learningPaths: learningPaths.data,
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
  }
}

function ExploreFilters(props) {
  const { session, categories, tags, courseAuthorLoading } = props

  function toggleExpand() {
    props.updateFilters(props.isExpanded ? false : true, "mobile")
  }

  const dispatch = useDispatch()

  const {
    searchQuery,
    category,
    difficulty,
    learningPath,
    source,
    remainingOnly,
    selectedTags,
    courseAuthorSearch,
    courseAuthorResults,
  } = props.filters

  let collectionLabel =
    session.collection && session.collection.label
      ? session.collection.label[0].value
      : "MobileMind"

  const orgId =
    session.group && session.group.uuid && session.group.uuid[0].value

  function handleChange(args) {
    dispatch(updateExploreFilters(args))
  }

  return (
    <form
      className={classNames(
        "filters explore",
        props.sidebar.isCollapsed && "collapsed",
        isMobile && props.isExpanded && "expanded"
      )}
    >
      <header style={{ marginBottom: 15 }}>
        <strong>Filter Courses</strong>
        <ButtonSmall
          onClick={() => {
            dispatch(resetExploreFilters())
          }}
        >
          Reset
        </ButtonSmall>
      </header>
      <div className="flexRow inputSearch">
        <span className="icon search" />
        <TextField
          variant="outlined"
          label="Search"
          value={searchQuery}
          onChange={(event) => {
            handleChange({ name: "searchQuery", value: event.target.value })
          }}
        />
      </div>

      <CategorySelect
        categories={categories}
        currentValue={category}
        onChange={(newCategory) => {
          handleChange({ name: "category", value: newCategory })
        }}
        allowedLevels={3}
        label={"Category"}
      />

      <FormControl className="inputSelect tags">
        <InputLabel
          style={{ display: "flex", alignItems: "center" }}
          shrink={true}
          id="label-tags-select"
        >
          <span
            style={{ marginTop: 0, marginRight: 5, position: "relative" }}
            className="icon tags"
          />
          Tags
        </InputLabel>
        <Box sx={{ marginTop: 3 }}>
          <TagSelect
            placeholder={"Start typing to search"}
            tags={tags}
            allowNewTags={false}
            selectedTags={selectedTags}
            addTag={(tag) => {
              handleChange({ name: "tags", value: tag, method: "add" })
            }}
            deleteTag={(tag) => {
              handleChange({ name: "tags", value: tag, method: "remove" })
            }}
          />
        </Box>
      </FormControl>

      <FormControl margin="normal" className="inputSelect difficulty">
        <span className="icon star" />
        <InputLabel id="label-difficulty-select">Difficulty</InputLabel>
        <Select
          labelId="label-difficulty-select"
          id="difficulty-select"
          value={difficulty}
          onChange={(event) => {
            handleChange({ name: "difficulty", value: event.target.value })
          }}
        >
          <MenuItem key={"any"} value={"any"}>
            Any
          </MenuItem>
          <MenuItem key={"Basic"} value={"Basic"}>
            Basic
          </MenuItem>
          <MenuItem key={"Intermediate"} value={"Intermediate"}>
            Intermediate
          </MenuItem>
          <MenuItem key={"Advanced"} value={"Advanced"}>
            Advanced
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl className="inputSelect learningPath">
        <span className="icon learning-path" />
        <InputLabel id="labelLearningPathSelect">Learning Paths</InputLabel>
        <Select
          labelId="labelLearningPathSelect"
          id="learningPathSelect"
          value={learningPath}
          onChange={(event) => {
            handleChange({ name: "learningPath", value: event.target.value })
          }}
        >
          <MenuItem key={"any"} value={"any"}>
            Any
          </MenuItem>
          {props.learningPaths &&
            _.orderBy(props.learningPaths, (path) => path.name, ["asc"]).map(
              (path) => {
                return (
                  <MenuItem key={path.field_lp_id} value={path.field_lp_id}>
                    {path.name}
                  </MenuItem>
                )
              }
            )}
        </Select>
      </FormControl>

      <div className="course-author-field">
        <FormControl variant="standard" className="inputSelect courseAuthor">
          {courseAuthorLoading && (
            <Box
              style={{
                position: "absolute",
                right: 0,
                top: 15,
                transform: "scale(.75)",
              }}
            >
              <Loading />
            </Box>
          )}
          <Autocomplete
            id="related-courses-select"
            options={courseAuthorResults ?? []}
            value={courseAuthorSearch}
            loadingText={
              courseAuthorSearch
                ? "Getting authors..."
                : "Start typing to search..."
            }
            getOptionLabel={(option) => {
              if (option?.field_first_name) {
                return (
                  he.decode(option.field_first_name) +
                  " " +
                  he.decode(option.field_last_name)
                )
              } else return courseAuthorSearch
            }}
            renderInput={(params) => (
              <TextField
                label={"Course Author"}
                variant="standard"
                {...params}
                value={courseAuthorSearch}
                onChange={(event) => {
                  dispatch(debouncedGetCourseAuthors(event.target.value))
                }}
                placeholder="Search for authors..."
              />
            )}
            onChange={(event, newValue) => {
              handleChange({
                name: "author_id",
                value: newValue?.uid ?? "",
              })
            }}
          />
        </FormControl>
      </div>

      {orgId && !isMMContentHidden(session) && (
        <FormControl className="inputSelect source">
          <span className="icon source" />
          <InputLabel id="labelSource">Source</InputLabel>
          <Select
            labelId="labelSource"
            id="sourceSelect"
            value={source}
            onChange={(event) => {
              handleChange({ name: "source", value: event.target.value })
            }}
          >
            <MenuItem key={"any"} value={"any"}>
              Any
            </MenuItem>
            <MenuItem key={"mobilemind"} value={"mobilemind"}>
              {collectionLabel}
            </MenuItem>
            <MenuItem key={orgId} value={orgId}>
              {session.group.label[0].value}
            </MenuItem>
          </Select>
        </FormControl>
      )}

      <FormControlLabel
        style={{ marginLeft: -10, marginTop: 15 }}
        control={
          <Checkbox
            style={{
              color: theme.palette.secondary.main,
            }}
            checked={remainingOnly}
            onChange={(event) => {
              handleChange({
                name: "remainingOnly",
                value: event.target.checked,
              })
            }}
          />
        }
        label="Remaining Courses Only"
      />
      <div className="toggle" onClick={toggleExpand}>
        <span className="icon" />
      </div>
    </form>
  )
}

export default connect(mapStateToProps)(ExploreFilters)
